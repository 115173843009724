<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card
          border-variant="primary"
          header="Diagnósticos"
          header-bg-variant="primary"
          header-text-variant="white"
          class="mb-5"
        >
          <b-card-text>
            <div class="row">
              <div class="col-md-12">
                <label class="typo__label">Seleccione CIE-10</label>
                <multiselect
                  track-by="id"
                  placeholder="Busca un CIE-10"
                  :options="cie10"
                  :searchable="true"
                  label="name"
                  :loading="isLoading"
                  :clear-on-select="false"
                  :allow-empty="false"
                  @search-change="searchCIE10"
                  @select="addCIE10"
                >
                </multiselect>
              </div>
              <div class="col-md-12">
                <b-table
                  small
                  striped
                  hover
                  :items="form.cie10"
                  :fields="fieldscie10"
                >
                  <template #cell(quantity)="data">
                    <b-input v-model="data.item.quantity" size="sm"></b-input>
                  </template>
                  <template #cell(hours)="data">
                    <b-input v-model="data.item.hours" size="sm"></b-input>
                  </template>
                  <template #cell(days)="data">
                    <b-input v-model="data.item.days" size="sm"></b-input>
                  </template>
                  <template #cell(description)="data">
                    <b-input
                      v-model="data.item.description"
                      size="sm"
                    ></b-input>
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      size="sm"
                      variant="danger"
                      @click="deleteDrug(data.item.id)"
                      >Eliminar</b-button
                    >
                  </template>
                </b-table>
              </div>
            </div>
          </b-card-text>
        </b-card>
        <b-card
          border-variant="primary"
          header="Receta"
          header-bg-variant="primary"
          header-text-variant="white"
        >
          <b-card-text>
            <div class="row">
              <div class="col-md-12">
                <label class="typo__label">Seleccione medicamentos</label>
                <multiselect
                  v-model="selectedDrug"
                  track-by="id"
                  placeholder="Añade medicamentos"
                  :options="drugs"
                  :searchable="true"
                  label="name"
                  :loading="isLoadingDrugs"
                  :clear-on-select="false"
                  :allow-empty="false"
                  @search-change="searchDrug"
                  @select="addDrug"
                >
                </multiselect>
              </div>
            </div>
            <div class="row">
              <b-table small striped hover :items="form.drugs" :fields="fields">
                <template #cell(quantity)="data">
                  <b-input v-model="data.item.quantity" size="sm"></b-input>
                </template>
                <template #cell(hours)="data">
                  <b-input v-model="data.item.hours" size="sm"></b-input>
                </template>
                <template #cell(days)="data">
                  <b-input v-model="data.item.days" size="sm"></b-input>
                </template>
                <template #cell(description)="data">
                  <b-input v-model="data.item.description" size="sm"></b-input>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    size="sm"
                    variant="danger"
                    @click="deleteDrug(data.item.id)"
                    >Eliminar</b-button
                  >
                </template>
              </b-table>
            </div>
          </b-card-text>
          <b-card-footer>
            <b-button variant="success" @click="saveAppointment"
              >Guardar
            </b-button>
          </b-card-footer>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";

export default {
  name: "DrugSell",
  components: { Multiselect },
  data() {
    return {
      cie10: [],
      doctors: [],
      selectedDrug: null,
      form: {
        cie10: [],
        drugs: [],
        appointment: this.$route.params.appointmentId
      },
      specialties: [],
      drugs: [],
      isLoading: false,
      isLoadingDrugs: false,
      person: "00000000",
      persons: [],
      no_client_document_number: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      filter: null,
      fields: [
        { key: "id", label: "#" },
        { key: "quantity", label: "Cantidad" },
        { key: "name", label: "Medicamento" },
        { key: "hours", label: "Horas" },
        { key: "days", label: "Días" },
        { key: "description", label: "Descripción" },
        { key: "actions", label: "Acciones" }
      ],
      fieldscie10: [
        { key: "id", label: "#" },
        { key: "name", label: "Diagnóstico" }
      ]
    };
  },
  mounted() {
    this.getSerie();
    this.getSpecialties();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Farmacia" },
      { title: "Venta medicamentos" }
    ]);
  },
  methods: {
    saveAppointment() {
      let form = { ...this.form };
      const cie10 = [];
      form.cie10.forEach(x => {
        cie10.push(x.id);
      });
      form.cie10 = cie10;
      ApiService.post("medical-prescription/", form).then();
    },
    getSpecialties() {
      ApiService.get("specialty", "?is_auxiliary=0").then(res => {
        this.specialties = res.data;
      });
    },
    selectSpecialty(selectedOption) {
      console.log(selectedOption);

      this.form.doctor = null;
      this.doctors = [];

      ApiService.get(
        "user-specialty/search/doctor",
        `?specialty=${selectedOption.id}`
      ).then(res => {
        this.doctors = res.data;
      });
    },
    searchCIE10(query) {
      if (query === "" || query.length < 3) {
        return false;
      }
      this.isLoading = true;
      if (typeof window.LIT !== "undefined") {
        clearTimeout(window.LIT);
      }
      window.LIT = setTimeout(() => {
        ApiService.get(`cie-10`, `?query=${query}`).then(res => {
          this.cie10 = res.data;
          this.isLoading = false;
        });
      }, 750);
    },
    searchDrug(query) {
      if (query === "" || query.length < 3) {
        return false;
      }
      this.isLoadingDrugs = true;
      if (typeof window.LIT !== "undefined") {
        clearTimeout(window.LIT);
      }
      window.LIT = setTimeout(() => {
        ApiService.get(`drug`, `?query=${query}`).then(res => {
          this.drugs = res.data;
          this.isLoadingDrugs = false;
        });
      }, 750);
    },
    addDrug(selectedOption) {
      // Check if already has this item
      const idx = this.form.drugs.findIndex(x => x.id === selectedOption.id);
      if (idx !== -1) {
        return false;
      }
      const drug = selectedOption;
      const o = {
        id: drug.id,
        name: drug.name,
        quantity: 1,
        hours: 0,
        days: 0
      };
      this.form.drugs.push(o);
    },
    addCIE10(selectedOption) {
      // Check if already has this item
      const idx = this.form.cie10.findIndex(x => x.id === selectedOption.id);
      if (idx !== -1) {
        return false;
      }
      const cie10 = selectedOption;
      const o = {
        id: cie10.id,
        name: cie10.name,
      };
      this.form.cie10.push(o);
    },
    getSerie() {
      // Check if I have a uuid in localStorage
      if (localStorage.getItem("pos_key") === null) {
        localStorage.clear();
        this.$router.push({ name: "login" });
      } else {
        ApiService.get(
          `machine-id/identifier`,
          `?identifier=${localStorage.pos_key}`
        )
          .then(() => {
            console.log("Esta máquina sí tiene una serie");
          })
          .catch(() => {
            this.$router.push({ name: "machine_id_create" });
          });
      }
    },
    selectProduct(product) {
      let quantity = document.getElementById(`quantity-${product.id}`).value;
      // Check if product exists
      let index = this.customLodash.findIndex(this.selectedProducts, {
        id: product.id
      });
      if (index === -1) {
        let prod = {
          id: product.id,
          quantity: parseInt(quantity),
          price: parseFloat(product.price),
          total: parseFloat(quantity) * parseFloat(product.price),
          name: product.name
        };
        this.selectedProducts.push(prod);
      } else {
        let currentQuantity = this.selectedProducts[index]["quantity"];
        currentQuantity += parseInt(quantity);
        this.selectedProducts[index]["quantity"] = currentQuantity;
        this.selectedProducts[index]["total"] =
          currentQuantity * parseFloat(product.price);
      }
    },
    onFiltered(filteredItems) {
      console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getProducts() {
      ApiService.get("drug").then(res => {
        this.products = res.data;
        this.totalRows = this.products.length;
      });
    },
    deleteDrug(drugId) {
      const filtered = this.form.drugs.filter(x => x.id !== parseInt(drugId));
      this.form.drugs = filtered;
    }
  },
  computed: {
    total() {
      return this.customLodash.sumBy(this.selectedProducts, "total");
    },
    buttonSellIsVisible() {
      return this.selectedProducts.length > 0;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
